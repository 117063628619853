






































/* eslint-disable */
import { Vue, Component, Watch } from "vue-property-decorator";
import { OtherModule } from "@/store/other";
import { api_user } from "@/api";
import { numFormat } from "@/util/string";
import { Encryption } from "@/util/encryption";
@Component
export default class extends Vue {
    info:any = {
        chapter:{
            id:''
        }
    }
  numFormat = numFormat;

  @Watch("$route", { immediate: true })
  watch_route() {
    this.answer_finish();
  }

  async answer_finish() {
    this.info = await api_user.answer_finish({
      chapter: this.id,
      level: +this.$route.query.level,
      course_id: Number(Encryption.base_dec(this.$route.params.id)),
    });
  }

  to_exam() {
    this.$router.push(
      `/user/course/${this.$route.params.id}/exam/${this.$route.params.chapterId}/?level=${this.$route.query.level}`
    );
  }
  get id() {
    return Number(Encryption.base_dec(this.$route.params.chapterId));
  }
  get is_mobile() {
    return OtherModule.is_mobile;
  }
}
